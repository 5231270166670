<template>
  <div>
    <!-- Header -->
    <v-container fluid class="pa-0">
      <v-row no-gutters style="height: 25vh; background: #d40643" align="center">
        <v-col cols="12">
          <v-card color="transparent" flat dark>
            <v-row no-gutters>
              <v-col cols="12">
                <div class="text-center centerNameEN" v-if="current_lang === 'en'">
                  {{ $vuetify.lang.t(`$vuetify.ticket_query.center_name`) }}
                </div>
                <div class="text-center centerNameZH" v-else>
                  {{ $vuetify.lang.t(`$vuetify.ticket_query.center_name`) }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col cols="12">
                <div class="text-center centerAddr">
                  {{ $vuetify.lang.t(`$vuetify.ticket_query.address_1`) }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <div class="text-center centerAddr">
                  {{ $vuetify.lang.t(`$vuetify.ticket_query.address_2`) }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Body -->
    <v-container fluid class="pa-0">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card flat>
            <v-card-text>
              <!-- Language Buttons -->
              <v-img @click="changeLang('zhHans')" v-if="current_lang === 'zhHant'" class="langButton" :src="require('@/assets/images/cnLangBtn.png')" width="12vw"></v-img>
              <v-img @click="changeLang('zhHant')" v-else class="langButton" :src="require('@/assets/images/tcLangBtn.png')" width="12vw"></v-img>
              <v-img @click="changeLang('en')" class="langButton langButtonEN" :src="require('@/assets/images/enLangBtn.png')" width="12vw"></v-img>

              <v-row>
                <v-col cols="12" class="mt-16">
                  <div class="text-center ticketCode" :class="{ ticketCodeEn: current_lang === 'en' }">{{ $vuetify.lang.t(`$vuetify.ticket_query.expire_title_1`) }}</div>
                  <div class="text-center ticketCode" :class="{ ticketCodeEn: current_lang === 'en' }">{{ $vuetify.lang.t(`$vuetify.ticket_query.expire_title_2`) }}</div>
                  <div class="text-center ticketCode" :class="{ ticketCodeEn: current_lang === 'en' }">{{ $vuetify.lang.t(`$vuetify.ticket_query.expire_title_3`) }}</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Error',
  computed: {
    ...mapState(['i18n']),
    current_lang() {
      return this.i18n.current_lang
    }
  },
  methods: {
    ...mapMutations(['SET_I18N_CURRENT_LANGUAGE']),
    changeLang(target_lang) {
      this.SET_I18N_CURRENT_LANGUAGE(target_lang)
    }
  },
  mounted() {
    const language = this.$route.params.language ? this.$route.params.language : 'zhHant'
    this.changeLang(language)
  }
}
</script>

<style scoped>
.centerNameZH {
  font-size: 3rem;
  font-weight: 900;
}

.centerNameEN {
  margin-top: 0.2rem;
  font-size: 2rem;
  font-weight: 900;
}

.centerAddr {
  font-size: 1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.langButton {
  position: absolute;
  cursor: pointer;
  top: 2vh;
  right: 4vw;
}

.langButtonEN {
  margin-top: 6rem;
}

.ticketCode {
  font-size: 1.3rem;
  font-weight: 600;
  color: black;
}

.ticketCodeEn {
  font-size: 1rem;
}

/* iPhone5 */
@media only screen and (max-width: 320px) {
  .centerNameZH {
    font-size: 2rem;
  }

  .centerNameEN {
    font-size: 1rem;
  }

  .centerAddr {
    font-size: 0.8rem;
  }

  .langButtonEN {
    margin-top: 1.5rem;
  }
}

/* iPhone6/7/8, Plus and iPhoneX*/
@media only screen and (min-width: 374px) and (max-width: 520px) {
  .centerNameZH {
    font-size: 2.5rem;
  }

  .centerNameEN {
    font-size: 1.5rem;
  }

  .centerAddr {
    font-size: 1rem;
  }

  .langButtonEN {
    margin-top: 2.1rem;
  }
}
</style>
